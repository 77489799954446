import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceChanges',
  standalone: true,
})
export class PriceChangesPipe implements PipeTransform {
  transform(value: any, type: any = 'class', compareValue: any = null) {
    const typeList: any = {
      icon: {
        lessThanZero: 'bi bi-caret-down-fill text-danger ',
        greaterThanZero: 'bi bi-caret-up-fill text-success',
      },
      class: {
        lessThanZero: 'text-danger',
        greaterThanZero: 'text-success',
      },
    };
    if (value) {
      if (typeList[type] && !compareValue) {
        if (parseInt(value) < 0) {
          return typeList[type].lessThanZero;
        } else if (value === 0) {
          return '';
        } else {
          return typeList[type].greaterThanZero;
        }
      } else {
        if (parseInt(value) < compareValue) {
          return typeList[type].lessThanZero;
        } else if (value === 0) {
          return '';
        } else {
          return typeList[type].greaterThanZero;
        }
      }
    } else {
      return '';
    }
  }
}
