import { Injectable } from '@angular/core';
import { LightstreamerClient, Subscription } from 'lightstreamer-client-web';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LightStreamerService {

  private client: LightstreamerClient;
  private subscription!: Subscription;

  constructor(private authService:AuthService) {
    // Create a new LightstreamerClient object and set up connection details
    this.client = new LightstreamerClient("http://192.168.1.91:8080","PROXY_NODE");
    this.client.connectionDetails.setUser('user1');
    const getLocalStorage=authService.getLocalstorage('accessToken')
    if(getLocalStorage){

      this.client.connectionDetails.setPassword(getLocalStorage);
    }
  }

  // Connect to Lightstreamer server
  connect() {
    this.client.connect();
  }

  // Subscribe to a specific data stream
  subscribeToData(itemName: string[], fields: string[], callback: (data: any) => void) {
    this.subscription = new Subscription('MERGE', itemName, fields);
    // this.subscription.setDataAdapter("ROBUST_PROXY_FOR_REMOTE_ADAPTER");
    
    // Add a listener to receive updates
    this.subscription.addListener({
      onItemUpdate: (update) => {
        console.log(`update :>> `, update);
        const data: any = {};
        fields.forEach((field) => {
          data[field] = update.getValue(field);
        });
        callback(data); // Pass the updated data to the callback function
      },
    });
    
    this.client.subscribe(this.subscription); // Subscribe to the data
  }

  // Disconnect from Lightstreamer server
  disconnect() {
    this.client.disconnect();
  }
}
